import React from "react";
import { graphql } from "gatsby";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { StaticImage } from "gatsby-plugin-image";
import SEO from "../components/core/Seo";
import BaseLayout from "../components/core/BaseLayout";
import styled from "styled-components";
import {
  WaveFooter,
  Section,
  Content,
  FeatureDuo,
  Headline,
  Subline,
  Text,
  MobileVisualization,
  DesktopVisualization,
  TextLinkExternal,
} from "../components/SubComponents";

import yearsSince from "../util/yearsSince";
import { ImageContainer, ImageMask } from "../components/Atoms";
import { SITEMAP_LINKS } from "../constants/linkdata";

const SubSection = styled.section``;

const Age = () => {
  const birthdate = "1994/8/12";
  return <>{yearsSince(birthdate)}</>;
};

const MovedDate = () => {
  const movedDate = "2013/3/1";
  return <>{yearsSince(movedDate)}</>;
};

const AboutPage = ({ location: { pathname } }) => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <SEO title={t("About Me")} path={pathname} />
      <Section id={"about"}>
        <Content>
          <FeatureDuo>
            <Text>
              <Headline large>
                <Trans>Hi there!</Trans>
              </Headline>
              <MobileVisualization>
                <ImageContainer>
                  <ImageMask />
                  <StaticImage
                    src="../assets/images/jpg/green.jpg"
                    alt="Sarah Kolb"
                    placeholder="tracedSVG"
                  />
                </ImageContainer>
              </MobileVisualization>
              <SubSection>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                    I'm Sarah, I'm <Age></Age> and I'm originally from a teeny village near Frankfurt, Germany.
                  </Trans>
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                    I grew up bilingually with a childhood stint in Singapore and moved to London <MovedDate></MovedDate> years ago to study Musical Theatre at London Studio Centre. Since then, I've been working as an actor, singer and voice over artist.
                  </Trans>
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                    I love challenges and learning new stuff! Besides my BA I am also trained in the Meisner acting technique, am a certified yoga and barre fit instructor, hold a BADC stage combat qualification and have a bunch of experience as a translator.
                  </Trans>
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                    In my free time I like to make music, knit, socialise, practise yoga, explore London and travel the world.
                  </Trans>
                </Subline>
                <Subline>
                  {/* prettier-ignore */}
                  <Trans>
                    If you're curious, you can find my artist profile with my full CV <TextLinkExternal href={SITEMAP_LINKS.SPOTLIGHT.URL}>here</TextLinkExternal>.
                  </Trans>
                </Subline>
              </SubSection>
            </Text>
            <DesktopVisualization>
              <ImageContainer>
                <ImageMask />
                <StaticImage
                  src="../assets/images/jpg/green.jpg"
                  alt="Sarah Kolb"
                  placeholder="tracedSVG"
                  tracedSVGOptions={{
                    color: "rgb(240, 240, 240)",
                  }}
                />
              </ImageContainer>
            </DesktopVisualization>
          </FeatureDuo>
        </Content>
        <WaveFooter />
      </Section>
    </BaseLayout>
  );
};

export default AboutPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "about"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
